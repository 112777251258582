$font-family-base: 'Yanone Kaffeesatz', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

$headings-font-weight: 700;

$btn-font-weight: 700;

@import 'bootstrap/scss/bootstrap.scss';

%default-padding {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

html {
  @media (min-width: 576px) {
    font-size: 18px;
  }
}

body {
  line-height: 1.75;
  color: var(--gray-dark);
  border-top: 8px solid #a0c8fe;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

img {
  width: 100%;

  &.wedding {
    margin-top: 3rem;
  }
}

.header {
  @extend %default-padding;

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

main.invitation {
  @extend %default-padding;
  padding-bottom: 0;
  background-color: #f6f9fe;

  h2 {
    margin-bottom: 2rem;
  }

  p.lead {
    font-size: 1.5rem;
  }

  a {
    display: inline-block;
    margin-top: 2rem;
  }

  svg {
    @media (min-width: 576px) {
      margin-top: -5rem;
    }

    @media (min-width: 992px) {
      margin-top: -8rem;
    }
  }
}

.bride-groom {
  @extend %default-padding;
  padding-top: 4rem;

  h2 {
    margin-bottom: 3rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  img {
    @media (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .row + .row {
    margin-top: 3rem;
  }

  .avatar-container {
    position: relative;
  }

  .bride-avatar {
    position: absolute;
    left: -1rem;
    width: 86px;
    height: 86px;
    background-image: url(../assets/bride-avatar.jpg);
    background-position: top;
    background-size: cover;
    border: 3px solid #f6f9fe;
    border-radius: 50%;

    @media (max-width: 576px) {
      right: -1rem;
      left: unset;
    }
  }

  .groom-avatar {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 86px;
    height: 86px;
    background-image: url(../assets/groom-avatar.jpg);
    background-position: center;
    background-size: cover;
    border: 3px solid #f6f9fe;
    border-radius: 50%;
  }
}

.event {
  @extend %default-padding;
  padding-bottom: 0;
  font-size: 1.25rem;
  background-color: #f6f9fe;

  h2 {
    margin-bottom: 2rem;
  }

  ul {
    padding-left: 0;

    @media (max-width: 576px) {
      margin-top: 2rem;
    }

    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 1rem 1.5rem;
      font-size: 1rem;
      color: #8564f2;
      background-color: #fff;
      list-style-type: circle;
      list-style-position: inside;
      border-radius: 0.25rem;

      span {
        color: #212529;
      }
    }
  }

  svg {
    margin-top: 5rem;
    fill: #fff;
  }
}

.destination {
  @extend %default-padding;
  font-size: 1.25rem;

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    @media (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  img {
    margin-bottom: 2rem;
  }
}

.footer {
  @extend %default-padding;
  color: #f6f9fe;
  background-color: var(--gray-dark);

  * {
    margin-bottom: 0;
  }
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)-$width /
      2 +
      px
      random($height)-$height /
      1.2 +
      px
      hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

.fireworks > .before,
.fireworks > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation(
    (
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards
    )
  );
}

.fireworks > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
